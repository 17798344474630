import * as React from "react";
import { Helmet } from "react-helmet";

import ImageGallery from "react-image-gallery";
import Header from "../components/header";
import fireplace from "../images/fireplace.webp";
import tableLight from "../images/table-light.webp";
import hotTub from "../images/background.webp";
import blueRoom from "../images/blue-room.webp";
import nook from "../images/nook.webp";
import nightSky from "../images/night-sky.webp";
import { IoLogoInstagram, IoLogoFacebook, IoLink } from "react-icons/io5";
import EmailForm from "../components/email-form";
const images = [
  {
    original: hotTub,
  },
  {
    original: fireplace,
  },
  {
    original: nightSky,
  },
  {
    original: tableLight,
  },
  {
    original: blueRoom,
  },
  {
    original: nook,
  },
];

const IndexPage = () => {
  const containerClasses = "flex-row h-full align-center bg-center";
  return (
    <main
      className={`mx-auto flex-row p-0 w-full min-h-screen font-serif bg-oc-green-dark`}
    >
      <div id="page-wrap" className={containerClasses}>
        <Header />
        <div id="main-wrap" className="p-8">
          <div id="this is it" className="h-80 md:h-auto lg:h-auto">
            <ImageGallery
              showNav={false}
              showThumbnails={false}
              showFullscreenButton={false}
              showPlayButton={false}
              autoPlay
              slideDuration={800}
              slideInterval={6000}
              items={images}
            />
          </div>
          <div className={"bg-kz-beige mt-10 p-10"}>
            <h1 className="text-3xl italic text-center">
              An idyllic escape from the city, the Overlook Chalet is a luxury
              home in the Catskill Mountains, just 2 hours from the George
              Washington bridge. With views of Hunter Mountain and gracious
              amenities, this is the perfect place for you and your loved ones
              to relax and enjoy the natural beauty around you.
            </h1>
            <a
              target="_blank"
              href="https://www.airbnb.com/rooms/41818269"
              className="text-center"
            >
              <div className="m-auto mt-10 bg-oc-red w-1/2 lg:w-1/2 md:w-full sm:w-full shadow-xl text-slate-100 text-xl pt-3 pb-3">
                Book Now on Airbnb
              </div>
            </a>
            <div className="flex-row md:flex lg:flex">
              <div className="w-full md:w-1/2 lg:w-1/2">
                <h1 className="text-2xl font-bold mt-10 italic">
                  Luxury Amenities
                </h1>
                <ul className="list-disc text-xl pl-10">
                  <li>10 Person Hot Tub with infinity swim lane</li>

                  <li>
                    Toshiba<sup>&reg;</sup> 4K Television &amp; Sonos
                    <sup>&reg;</sup> Surround Sound
                  </li>
                  <li>
                    <a href="/hvac">
                      Custom climate control in all rooms -{" "}
                      <span className="text-blue-800">
                        Learn more <IoLink className="inline" />
                      </span>
                    </a>
                  </li>
                  <li>
                    White noise by Dohm<sup>&reg;</sup>
                  </li>
                  <li>Outdoor Firepit</li>
                  <li>Wood-burning living room Fireplace</li>
                </ul>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/2">
                <h1 className="text-2xl font-bold mt-10 italic">
                  Sleeping Arrangements
                </h1>
                <ul className="list-disc text-xl pl-10">
                  <li>
                    <b>Primary:</b> King Bed with mountain views and an en-suite
                    bathroom
                  </li>
                  <li>
                    <b>"The Blue Room":</b> Queen Bed with forest views
                  </li>
                  <li>
                    <b>"The Green Room":</b> King Bed with forest views
                  </li>
                  <li>
                    <b>Upstairs 1:</b> 2 Queen Beds with forest views
                  </li>
                  <li>
                    <b>Upstairs 2:</b> King Bed with mountain views
                  </li>
                </ul>
              </div>
            </div>
            <EmailForm />
            <div>
              <h1 className="text-center text-3xl mt-4">Socials and whatnot</h1>
              <div className="flex w-full items-center content-center text-6xl">
                <a
                  className="block m-auto text-right"
                  href="https://www.facebook.com/people/The-Overlook-Chalet/100084695905693/"
                  target="_blank"
                >
                  <IoLogoFacebook />
                </a>
                <a
                  className="block m-auto text-left"
                  href="https://www.instagram.com/overlookchalet/"
                  target="_blank"
                >
                  <IoLogoInstagram />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default IndexPage;

export const Head = () => (
  <>
    <title>Overlook Chalet</title>
    <Helmet>
      <script>
        {`
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '264446943242284');
fbq('track', 'PageView');
`}
      </script>
      <noscript>
        {`
        <img
          height="1"
          width="1"
          style="display:none"
          src="https://www.facebook.com/tr?id=264446943242284&ev=PageView&noscript=1"
        />
        `}
      </noscript>
    </Helmet>
  </>
);
