import React from "react";
import { slide as Menu } from "react-burger-menu";
import logo from "../images/overlooklogo.png";
import * as cx from "classnames";

const burgerStyles = {
  bmBurgerButton: {
    position: "fixed",
    width: "36px",
    height: "30px",
    right: "36px",
    top: "36px",
  },
  bmBurgerBars: {
    background: "#373a47",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "#bdc3c7",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
  },
  bmMenu: {
    background: "#373a47",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#b8b7ad",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
  bmItem: {
    display: "block",
    padding: "1rem",
    fontSize: "1.5rem",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.4)",
  },
};

const Header = () => {
  const parentClasses = cx(
    "h-24",
    "flex",
    "text-oc-gray",
    "text-lg",
    "bg-amber-50/80",
    "w-full",
    "justify-between",
    "border-b-2",
    "border-slate-700"
  );
  return (
    <div className={parentClasses}>
      <div className="w-1/6 hidden md:flex lg:flex"></div>
      <div className="grow m-auto h-full items-center">
        <a id="home" href="/">
          <img alt="logo" className="h-full p-4 m-auto" src={logo} />
        </a>
      </div>
      <div className="w-1/6 hidden md:flex lg:flex pl-4 pr-4 align-middle text-center m-auto">
        <a
          className="border-2 border-oc-red pt-2 pb-2 pl-4 pr-4"
          href="https://www.airbnb.com/rooms/41818269"
        >
          Book Now
        </a>
      </div>
    </div>
  );
};

export default Header;
